import logo from './logo.svg';
import './App.css';
import './hover.css';

import React, { Suspense, useEffect, useState } from 'react';

function Privacy() {

    return (
        <div style={{ overflow: 'hidden', whiteSpace: 'pre-line', padding: 50, paddingTop: 0 }}>


            <p style={{ marginLeft: '0px' }}><br /></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '16px', fontFamily: 'Helvetica Neue' }}><strong>PRIVACY STATEMENT</strong></span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}><strong>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</strong></span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>We use the information you provide to perform account management so that you can save your progress in the app.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}><strong>SECTION 2 - DISCLOSURE</strong></span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}><strong>SECTION 3 - THIRD-PARTY SERVICES</strong></span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}><strong>Links</strong></span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}><strong>SECTION 4 - SECURITY</strong></span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}><strong>SECTION 5 - COOKIES</strong></span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>We use cookies to maintain session of your user. It is not used to personally identify you on other websites.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}><strong>SECTION 6 - AGE OF CONSENT</strong></span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}><strong>SECTION 7 - CHANGES TO THIS PRIVACY POLICY</strong></span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.</span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}><strong>SECTION 8 - REFUND POLICY</strong></span></p>

            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>
                We offer subscriptions to our services, which provide access to exclusive content and features. Once you purchase a subscription, you will have immediate access to the content and features that come with it.</span></p>

            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>
                Please note that we do not offer refunds for any subscription purchases. Once you have purchased a subscription, it is considered a final sale and cannot be refunded.</span></p>

            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>
                We encourage our customers to carefully review the features and benefits of our subscriptions before making a purchase. If you have any questions about our subscriptions, please feel free to contact us before making a purchase.</span></p>

            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>
                In the event that we terminate your subscription due to a breach of our terms of service or other policy violations, you will not be entitled to a refund.

            </span></p>

            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>
                Thank you for your understanding and for choosing our services. If you have any further questions or concerns about our refund policy, please do not hesitate to contact us.</span></p>

            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}><strong>QUESTIONS AND CONTACT INFORMATION</strong></span></p>
            <p style={{ marginLeft: '0px' }}><span style={{ fontSize: '13px', fontFamily: 'Helvetica Neue' }}>If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Privacy Compliance Officer by mail at contact@upscready.com</span><br /></p>



        </div>
    );
}

export default Privacy;

