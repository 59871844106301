import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as ReactDOM from "react-dom";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Privacy from './Privacy';
import Terms from './Terms';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    loader: () => { return null },

  },
  {
    path: "privacy",
    element: <Privacy />,
    loader: () => {
      // alert('hi')
      return null
    },
  },
  {
    path: "terms",
    element: <Terms />,
    loader: () => {
      // alert('hi')
      return null
    },
  }
]);


ReactDOM.render(
  <React.StrictMode>
    {/* <router /> */}
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);


// ReactDOM.createRoot(document.getElementById("root")).render(
//   <RouterProvider router={router} />
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
