import logo from './logo.svg';
import './App.css';
import './hover.css';

import React, { Suspense, useEffect, useState } from 'react';

function Terms() {

    return (
        <div style={{ overflow: 'hidden', whiteSpace: 'pre-line', padding: 50, paddingTop: 30 }}>



            {/* {`
           Terms and Conditions:
           
           
           Welcome to our app/website, which provides reading content in an organized manner, practice questions, and tests. Please read these terms and conditions carefully as they govern your use of our services.
           
           Acceptance of Terms
           By accessing or using our app/website, you agree to be bound by these terms and conditions, as well as any additional terms and conditions that may apply to specific services or features. If you do not agree to these terms and conditions, please do not use our services.
           
           Use of Services
           Our app/website provides access to reading content in an organized manner, practice questions, and tests. You may use our services for your personal, non-commercial use only. You may not use our services for any illegal or unauthorized purpose, and you agree to comply with all applicable laws and regulations.
           
           Intellectual Property
           All content provided through our services, including text, graphics, images, and software, is owned by us or our licensors and is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, modify, distribute, or display any of our content without our prior written consent.
           
           Privacy
           We collect, use, and disclose your personal information in accordance with our privacy policy. By using our services, you consent to the collection, use, and disclosure of your personal information as described in our privacy policy.
           
           Subscription and Payments
           Some of our services may require a subscription or payment. If you choose to purchase a subscription or make a payment, you agree to pay all applicable fees and taxes. Subscription fees are non-refundable.
           
           Disclaimer of Warranties
           Our services are provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of our services or the information, content, materials, or products included in our services.
           
           Limitation of Liability
           We will not be liable for any damages of any kind arising from the use of our services, including, but not limited to, direct, indirect, incidental, punitive, and consequential damages.
           
           Indemnification
           You agree to indemnify, defend, and hold us harmless from and against any and all claims, damages, liabilities, costs, and expenses, including attorneys' fees, arising from or related to your use of our services or any breach of these terms and conditions.
           
           Modification of Terms and Conditions
           We may modify these terms and conditions at any time by posting the modified terms and conditions on our app/website. Your continued use of our services after the posting of the modified terms and conditions will constitute your acceptance of the modified terms and conditions.
           
           Termination
           We may terminate your access to our services at any time if you violate these terms and conditions or any other reason.
           
           `} */}


            <p style={{ textAlign: 'start' }}><span style={{ color: 'rgb(55,65,81)', fontSize: '18px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}><strong>Terms and Conditions:</strong></span></p>
            <p style={{ textAlign: 'start' }}><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}>Welcome to our app/website, which provides reading content in an organized manner, practice questions, and tests. Please read these terms and conditions carefully as they govern your use of our services.</span></p>
            <p><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}><strong>Acceptance of Terms</strong></span></p>
            <p style={{ textAlign: 'start' }}><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}>By accessing or using our app/website, you agree to be bound by these terms and conditions, as well as any additional terms and conditions that may apply to specific services or features. If you do not agree to these terms and conditions, please do not use our services.</span></p>
            <p><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}><strong>Use of Services</strong></span></p>
            <p style={{ textAlign: 'start' }}><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}>Our app/website provides access to reading content in an organized manner, practice questions, and tests. You may use our services for your personal, non-commercial use only. You may not use our services for any illegal or unauthorized purpose, and you agree to comply with all applicable laws and regulations.</span></p>
            <p><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}><strong>Intellectual Property</strong></span></p>
            <p style={{ textAlign: 'start' }}><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}>All content provided through our services, including text, graphics, images, and software, is owned by us or our licensors and is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, modify, distribute, or display any of our content without our prior written consent.</span></p>
            <p><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}><strong>Privacy</strong></span></p>
            <p style={{ textAlign: 'start' }}><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}>We collect, use, and disclose your personal information in accordance with our privacy policy. By using our services, you consent to the collection, use, and disclosure of your personal information as described in our privacy policy.</span></p>
            <p><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}><strong>Subscription and Payments</strong></span></p>
            <p style={{ textAlign: 'start' }}><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}>Some of our services may require a subscription or payment. If you choose to purchase a subscription or make a payment, you agree to pay all applicable fees and taxes. Subscription fees are non-refundable.</span></p>
            <p><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}><strong>Disclaimer of Warranties</strong></span></p>
            <p style={{ textAlign: 'start' }}><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}>Our services are provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of our services or the information, content, materials, or products included in our services.</span></p>
            <p><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}><strong>Limitation of Liability</strong></span></p>
            <p style={{ textAlign: 'start' }}><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}>We will not be liable for any damages of any kind arising from the use of our services, including, but not limited to, direct, indirect, incidental, punitive, and consequential damages.</span></p>
            <p><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}><strong>Indemnification</strong></span></p>
            <p style={{ textAlign: 'start' }}><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}>You agree to indemnify, defend, and hold us harmless from and against any and all claims, damages, liabilities, costs, and expenses, including attorneys' fees, arising from or related to your use of our services or any breach of these terms and conditions.</span></p>
            <p><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}><strong>Modification of Terms and Conditions</strong></span></p>
            <p style={{ textAlign: 'start' }}><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}>We may modify these terms and conditions at any time by posting the modified terms and conditions on our app/website. Your continued use of our services after the posting of the modified terms and conditions will constitute your acceptance of the modified terms and conditions.</span></p>
            <p><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}><strong>Termination</strong></span></p>
            <p><span style={{ color: 'rgb(55,65,81)', fontSize: '16px', fontFamily: 'Söhne, ui-sans-serif, system-ui, -apple-system' }}>We may terminate your access to our services at any time if you violate these terms and conditions or any other reason.</span></p>
        </div>
    );
}

export default Terms;

